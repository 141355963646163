<template>
  <el-dialog :visible="dialogVisible"
             :title="title"
             width="520px"
             @open="getUserList"
             @close="closeDialog">
    <el-select style="width:400px"
               v-model="value"
               filterable
               remote
               clearable
               reserve-keyword
               placeholder="请输入新管理员姓名"
               :remote-method="remoteMethod"
               :loading="loading">
      <el-option v-for="item in userList"
                 :key="item.code"
                 :label="item.name + '-' + item.username"
                 :value="item.code"></el-option>
    </el-select>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary"
                 @click="save">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { api } from '/src/api/base';
export const UserSimpleList = api()('user.simple.list.json');
export const userChangeEnterpriseAdmin = api()(
  'user.change.enterprise.admin.json'
);
export default {
  props: {
    dialogVisible: Boolean,
    code: String,
    admin: Object,
  },
  data () {
    return {
      value: '',
      loading: false,
      userList: [],
      title: '',
    };
  },
  mounted () {

    if (this.admin) {
      this.title = '更换企业管理员'
    }
    else {
      this.title = '设置企业管理员'
    }
  },
  methods: {
    async getUserList (keywords) {
      this.loading = true;
      const res = await UserSimpleList({
        pageNumber: 1,
        pageSize: 10,
        keywords,
        isAuthenticated: true,
        roless: ['maker']
      });
      this.loading = false;
      this.userList = res.list;
    },
    remoteMethod (keywords) {
      this.getUserList(keywords);
    },
    closeDialog () {
      this.$emit('show', false);
      this.$emit('close');
      this.value = '';
    },
    async save () {
      if (!this.value) {
        return this.$message.error('请选择新的管理员');
      }
      await userChangeEnterpriseAdmin({
        // oldCode: this.code,
        code: this.value
      });
      this.$message.success('管理员更换成功');
      this.closeDialog();
    }
  }
};
</script>

<style scoped>
.el-dialog__body {
  display: flex;
  justify-content: center;
}
</style>
